import { INITIAL_INVOICES_SEARCH_PARAMS } from 'src/views/billings/invoices/partials/Constants'
import { SET_INVOICES_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  searchParams: INITIAL_INVOICES_SEARCH_PARAMS,
}

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICES_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}

export default invoicesReducer
