export const INITIAL_TAX_CLASSES_SEARCH_PARAMS = {
  page: 1,
  name: '',
  id: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 10,
}

export const INITIAL_TAX_CLASS = {
  id: null,
  status: 0,
  sort_order: 20,
  descriptions: [],
}

export const INITIAL_DESCRIPTIONS = [
  {
    name: '',
    language_id: 1,
  },
  {
    name: '',
    language_id: 2,
  },
]
