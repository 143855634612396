import { SET_CLIENT_PRICINGS_SEARCH_PARAMS } from 'src/actions/actionType'
import { INITIAL_CLIENT_PRICINGS_SEARCH_PARAMS } from 'src/views/billings/client-pricing/partials/Constants'

const initialState = {
  searchParams: INITIAL_CLIENT_PRICINGS_SEARCH_PARAMS,
}

const ClientPricingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_PRICINGS_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
      }
    default:
      return state
  }
}

export default ClientPricingsReducer
