import { INITIAL_WAREHOUSES_SEARCH_PARAMS } from 'src/views/warehouses/partials/Constants'
import { SET_WAREHOUSES_SEARCH_PARAMS, WAREHOUSE_DATA } from '../actions/actionType'
const initialState = {
  warehouses: [],
  searchParams: INITIAL_WAREHOUSES_SEARCH_PARAMS,
}

export default function WarehouseReducer(state = initialState, action) {
  switch (action.type) {
    case WAREHOUSE_DATA:
      return { ...state, warehouses: action.payload }
    case SET_WAREHOUSES_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
