import { INITIAL_CLIENTS_SEARCH_PARAMS } from 'src/views/clients/clients/partials/Constants'
import { CLIENTS_DATA, SET_CLIENTS_SEARCH_PARAMS } from '../actions/actionType'
const initialState = {
  clients: [],
  searchParams: INITIAL_CLIENTS_SEARCH_PARAMS,
}
export default function ClientsReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_DATA:
      return { ...state, clients: action.payload }
    case SET_CLIENTS_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
