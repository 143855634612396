import http from '../utils/http-common'

const getAll = (param, cancelToken) => {
  return http.get('/clients', { params: param, cancelToken: cancelToken?.token })
}
const get = (id) => {
  return http.get(`/clients/${id}`)
}
const create = (data) => {
  return http.post('/clients', data)
}

const update = (id, data) => {
  return http.put(`/clients/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/clients/${id}`)
}

const removeAll = () => {
  return http.delete(`/clients`)
}

const findByTitle = (title) => {
  return http.get(`/clients?title=${title}`)
}

const findByClientName = (name) => {
  return http.get(`/clients?list=auto-complete&client_name_like=${name}`)
}

const createContact = (cId, data) => {
  return http.post(`/client/add-contact/${cId}`, data)
}

const updateContact = (id, data) => {
  return http.post(`/client/edit-contact/${id}`, data)
}

const removeContact = (id) => {
  return http.delete(`/client/delete-contact/${id}`)
}

const getSubClients = (params) => {
  return http.post(`/clients/sub/clients`, params)
}

const findAllClientName = () => {
  return http.get(`/clients?list=auto-complete-all`)
}

const updateLineCharge = (id, data) => {
  return http.put(`/client-line-charges/${id}`, data)
}

const addLineCharge = (data) => {
  return http.post(`/client-line-charges`, data)
}

const deleteLineCharge = (id) => {
  return http.delete(`/client-line-charges/${id}`)
}

const addScale = (data) => {
  return http.post(`/client-cost-scales`, data)
}

const deleteSacle = (id) => {
  return http.delete(`/client-cost-scales/${id}`)
}

const updateScale = (id, data) => {
  return http.put(`/client-cost-scales/${id}`, data)
}

const getCSVTemplate = () => {
  return http.get(`/import/client-structure`)
}

const importCSV = (data) => {
  return http.post(`/import/clients`, data)
}

const createIntegration = (data) => {
  return http.post(`/integrations`, data)
}

const updateIntegration = (id, data) => {
  return http.put(`/integrations/${id}`, data)
}

const getIntegrations = (clientId) => {
  return http.get(`/integrations?client_id=${clientId}`)
}

const executeIntegration = (params) => {
  return http.post(`/integrations/execute`, params)
}

const deleteIntegration = (id) => {
  return http.delete(`/integrations/${id}`)
}

const emailExportExcel = (param) => {
  return http.get(`/export/clients`, { params: param })
}

const getIntegrationLogs = (integrationId, param) => {
  return http.get(`/integrations-logs?integration_id=${integrationId}`, { params: param })
}

const updateClientItemInventory = (params) => {
  return http.post(`/clients/update/item/inventory`, params)
}

const uploadClientLogo = (id, data) => {
  return http.post(`/upload-client-logo/${id}`, data)
}

const ClientDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  findByClientName,
  createContact,
  updateContact,
  removeContact,
  getSubClients,
  findAllClientName,
  updateLineCharge,
  addLineCharge,
  deleteLineCharge,
  updateScale,
  addScale,
  deleteSacle,
  getCSVTemplate,
  importCSV,
  createIntegration,
  updateIntegration,
  getIntegrations,
  executeIntegration,
  deleteIntegration,
  emailExportExcel,
  getIntegrationLogs,
  updateClientItemInventory,
  uploadClientLogo,
}
export default ClientDataService
