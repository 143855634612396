export const ROUTE = {
  HOME: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboards/dashboard',
  DASHBOARD_EXTERNAL: '/dashboard',
  RETURN_LIST: '/returns',
  RETURN_CREATE: '/returns/create',
  RETURN_EDIT: '/returns/edit',
  RETURN_VIEW: '/returns/view',
  SUPPLIER_LIST: '/suppliers',
  SUPPLIER_CREATE: '/suppliers/create',
  SUPPLIER_EDIT: '/suppliers/edit',
  SUPPLIER_VIEW: '/suppliers/view',
  COUNTRIES_LIST: '/system/countries',
  COUNTRIES_CREATE: '/system/countries/create',
  COUNTRIES_EDIT: '/system/countries/edit',
  COUNTRIES_VIEW: '/system/countries/view',
  PROVINCES_LIST: '/system/provinces',
  PROVINCES_CREATE: '/system/provinces/create',
  PROVINCES_EDIT: '/system/provinces/edit',
  PROVINCES_VIEW: '/system/provinces/view',
  ORDER_LIST: '/orders',
  ORDER_CREATE: '/orders/create',
  ORDER_EDIT: '/orders/edit',
  ORDER_VIEW: '/orders/view',
  PURCHASE_ORDER_LIST: '/po',
  PURCHASE_ORDER_CREATE: '/po/create',
  PURCHASE_ORDER_EDIT: '/po/edit',
  PURCHASE_ORDER_VIEW: '/po/view',
  PURCHASE_ORDER_IMPORT: '/po/import',
  ITEM_LIST: '/products/items',
  ITEM_EDIT: '/products/items/edit',
  ITEM_VIEW: '/products/items/view',
  ITEM_CREATE: '/products/items/create',
  ITEM_IMPORT: '/products/items/import',
  RECEIVING_LIST: '/receiving',
  RECEIVING_CREATE: '/receiving/create',
  RECEIVING_EDIT: '/receiving/edit',
  RECEIVING_VIEW: '/receiving/view',
  APPOINTMENT_LIST: '/appointments',
  APPOINTMENT_CREATE: '/appointments/create',
  APPOINTMENT_EDIT: '/appointments/edit',
  APPOINTMENT_CALENDAR: '/appointments/calendar',
  APPOINTMENT_IMPORT: '/appointments/import',
  APPOINTMENT_VIEW: '/appointments/view',
  ZONES_LIST: '/zones',
  ZONES_CREATE: '/zones/create',
  ZONES_EDIT: '/zones/edit',
  ZONES_VIEW: '/zones/view',
  SHIPPING_LIST: '/shipping',
  SHIPPING_CREATE: '/shipping/create',
  SHIPPING_EDIT: '/shipping/edit',
  SHIPPING_VIEW: '/shipping/view',
  SHIPPING_TRANSFER_PACKAGE: '/shipping/transfer-package',
  CLIENT_LIST: '/clients/clients',
  CLIENT_CREATE: '/clients/clients/create',
  CLIENT_EDIT: '/clients/clients/edit',
  CLIENT_VIEW: '/clients/clients/view',
  CLIENT_IMPORT: '/clients/clients/import',
  WAREHOUSE_LIST: '/warehouses',
  WAREHOUSE_CREATE: '/warehouses/create',
  WAREHOUSE_EDIT: '/warehouses/edit',
  WAREHOUSE_VIEW: '/warehouses/view',
  CLIENT_PRICING_LIST: '/billing/client-pricing',
  CLIENT_PRICING_CREATE: '/billing/client-pricing/create',
  CLIENT_PRICING_EDIT: '/billing/client-pricing/edit',
  CLIENT_PRICING_VIEW: '/billing/client-pricing/view',
  INVOICE_LIST: '/billing/invoices',
  INVOICE_CREATE: '/billing/invoices/create',
  INVOICE_EDIT: '/billing/invoices/edit',
  INVOICE_VIEW: '/billing/invoices/view',
  INVOICE_IMPORT: '/billing/invoices/import',
  TAX_RATES_LIST: '/billing/taxes/tax-rates',
  TAX_RATES_CREATE: '/billing/taxes/tax-rates/create',
  TAX_RATES_EDIT: '/billing/taxes/tax-rates/edit',
  TAX_RATES_VIEW: '/billing/taxes/tax-rates/view',
  TAX_CLASSES_LIST: '/billing/taxes/tax-classes',
  TAX_CLASSES_CREATE: '/billing/taxes/tax-classes/create',
  TAX_CLASSES_EDIT: '/billing/taxes/tax-classes/edit',
  TAX_CLASSES_VIEW: '/billing/taxes/tax-classes/view',
}
