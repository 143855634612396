import http from '../utils/http-common'

const getAll = (param, cancelToken) => {
  return http.get('/tax-class', { params: param, cancelToken: cancelToken?.token })
}
const get = (id) => {
  return http.get(`/tax-class/${id}`)
}
const create = (data) => {
  return http.post('/tax-class', data)
}

const update = (id, data) => {
  return http.put(`/tax-class/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/tax-class/${id}`)
}

const removeAll = () => {
  return http.delete(`/tax-class`)
}

const findByTitle = (title) => {
  return http.get(`/tax-class?title=${title}`)
}
const TaxClassesDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
}
export default TaxClassesDataService
