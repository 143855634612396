export const INITIAL_WAREHOUSES_SEARCH_PARAMS = {
  id: '',
  name: '',
  country_id: '',
  province_id: '',
  status: '',
  order_processing: '',
  order_by: 'id',
  sort_method: 'desc',
  limit: 15,
  page: 1,
}
