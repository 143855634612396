export const INITIAL_INVOICES_SEARCH_PARAMS = {
  invoice_status_id: '',
  client_id: '',
  client_name: '',
  billing_category_id: '',
  billing_line_charge_id: '',
  year: '',
  month: '',
  comment: '',
  invoice_date: '',
  due_date: '',
  status: '',
  account_manager: '',
  invoice_id: '',
  date_from: '',
  date_to: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const CONSTANT_BILLING_LINE_CHARGE_ID = [45, 46, 47, 48, 97, 99]

export const CONSTANT_BILLING_LINE_CHARGE_PHARMACIE_PICARD = [110]

export const BILLING_UOM_DECIMALS = [9, 14, 29] // [HOUR, METER, SQUARE_FOOT]

export const INVOICE_TEXT = {
  FACTURE_INVOICE: 'Facture / Invoice',
  INVOICE_NUMBER: 'Numéro / Number',
  INVOICE_DATE: 'Date',
  BILL_TO: 'Facture À / Bill To',
  GROUP_3DM: 'Group 3DM',
  GROUP_3DM_ADDRESS: '328 rue Avro',
  GROUP_3DM_ADDRESS_CITY: 'Pointe-Claire, QC, H9R 5W5',
  GROUP_3DM_PHONE: '(514) 695-9922',
  GROUP_3DM_EMAIL: 'info@3-dm.com',
  INVOICE_QUANTITY_FR: 'Qté',
  INVOICE_QUANTITY_EN: 'Qty',
  INVOICE_PRICE_FR: 'Prix',
  INVOICE_PRICE_EN: 'Price',
  INVOICE_AMOUNT_FR: 'Montant',
  INVOICE_AMOUNT_EN: 'Amount',
  INVOICE_TPS_GST: 'TPS/GST 123097107',
  INVOICE_PST_QST: 'PST/QST 1009433739',
  INVOICE_CURRENCY: 'Devise / Currency',
  INVOICE_CAD: 'CAD ($)',
  INVOICE_SUBTOTAL: 'Sous-Total / Subtotal',
  INVOICE_TPS_GST_HST: 'TPS / GST / HST',
  INVOICE_TVQ_PST: 'TVQ / PST',
  INVOICE_TOTAL: 'Total',
  INVOICE_MESSAGE: 'Message',
}

export const INITIAL_CHARGE = {
  client_id: '',
  date: '',
  billing_category_id: null,
  charge_type: '',
  quantity: '',
  price: '',
  billing_uom_id: null,
  description: null,
}
