import { SET_TAX_CLASSES_SEARCH_PARAMS } from 'src/actions/actionType'
import { INITIAL_TAX_CLASSES_SEARCH_PARAMS } from 'src/views/billings/taxes/tax-classes/partials/Constants'

const initialState = {
  searchParams: INITIAL_TAX_CLASSES_SEARCH_PARAMS,
}

const TaxClassesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAX_CLASSES_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
      }
    default:
      return state
  }
}

export default TaxClassesReducer
