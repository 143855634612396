import { INITIAL_TAX_RATES_SEARCH_PARAMS } from 'src/views/billings/taxes/tax-rates/partials/Constants'
import { TAX_RATES_DATA, SET_TAX_RATES_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  taxRates: [],
  searchParams: INITIAL_TAX_RATES_SEARCH_PARAMS,
}

export default function TaxRatesReducer(state = initialState, action) {
  switch (action.type) {
    case TAX_RATES_DATA:
      return { ...state, taxRates: action.payload }
    case SET_TAX_RATES_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
