export const INITIAL_CLIENT_PRICINGS_SEARCH_PARAMS = {
  client_id: '',
  currency_id: '',
  billing_line_charge_id: '',
  effective_date: '',
  revision_date: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}
