import { CButton, CCol, CContainer, CRow } from '@coreui/react-pro'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from 'src/actions'
import logo3DM from 'src/assets/brand/3dm.svg'
import { ACCESS_TOKEN_NAME, BTN_COLOR, COMMON } from 'src/constants/Constants'
import { ROUTE } from 'src/constants/RouteConstants'
import { isUserEmployee } from 'src/selectors/AuthSelector'

const Page500 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(COMMON)
  const isEmployee = useSelector(isUserEmployee)
  const token = localStorage.getItem(ACCESS_TOKEN_NAME)

  const logoutUser = () => {
    setTimeout(() => {
      dispatch(logout())
      navigate(ROUTE.LOGIN, { replace: true })
      window.location.reload()
    }, 50)
  }

  const redirectUser = () => {
    setTimeout(() => {
      navigate(ROUTE.HOME, { replace: true })
      window.location.reload()
    }, 50)
  }

  useEffect(() => {
    if (!token) {
      logoutUser()
    }
  }, [token])

  return (
    <div className="min-vh-100">
      <div className="d-flex flex-row align-items-center min-vh-100">
        <CContainer>
          <CRow className="justify-content-top page-not-found-logo-container">
            <img src={logo3DM} alt={'logo'} className="page-not-found-logo"></img>
          </CRow>
          <CRow className="justify-content-center">
            <CCol md={4}>
              <div className="clearfix">
                <h1 className="display-3 me-4">{t('code_500')}</h1>
                <h4 className="pt-3">{t('msg_500')}</h4>
                <p className="text-medium-emphasis float-start">{t('desc_500')}</p>
              </div>
            </CCol>
          </CRow>
          <CRow className="justify-content-center go-to-dashboard-container">
            <CCol sm={4}>
              <div className="clearfix">
                <div className="display-3 me-4">
                  <CCol sm={12} className="mt-4">
                    <CButton color={BTN_COLOR.CREATE} type={'button'} onClick={redirectUser}>
                      {t(isEmployee ? 'go_to_dashboard' : 'go_to_order_list')}
                    </CButton>
                    <CButton
                      color={BTN_COLOR.CREATE}
                      className="mx-3"
                      type={'button'}
                      onClick={logoutUser}
                    >
                      {t('re_login')}
                    </CButton>
                  </CCol>
                </div>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  )
}

export default Page500
