export const INITIAL_CLIENTS_SEARCH_PARAMS = {
  id: '',
  client_name_like: '',
  email: '',
  email_like: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const INITIAL_CLIENT = {
  status: 1,
  default_warehouse_id: '',
  department_id: '',
  client_name: '',
  email: '',
  legal_name: '',
  industry_id: '',
  website: '',
  address1: '',
  address2: '',
  country_id: '',
  province_id: '',
  city: '',
  postal_code: '',
  bill_to: 1,
  bill_address1: '',
  bill_address2: '',
  bill_country_id: '',
  bill_province_id: '',
  bill_city: '',
  bill_postal_code: '',
  pre_alert: 60,
  processing_type_id: '',
  partial_kit_processing: '',
  batch_replenishment: '',
  modify_order: '',
  order_modification: '',
  picking_method_id: '',
  fulfillment_strategy_id: '',
  parent_id: '',
  packing_slips: '0',
  qa_release_needed: 1,
  template_id: '',
  inventory_source: '',
  order_processing: '',
  priority: [],
  phone: '',
  logo: '',
  contacts: [],
  carriers: [],
  tier_id: '',
  csr: [],
  account_manager: [],
  rates_shop: 0,
  packing_slip_footer: '',
  default_carrier_id: '',
  items_per_cart: 0,
  po_comment: '',
  tax_class_id: '',
  currency_id: '',
  supplies_3dm: [],
  order_threshold: 0,
  retention_handling_process: '',
  have_barcodes: 1,
}

export const PLATFORM_SETTINGS = ['Item', 'Inventory', 'PO', 'Tracking', 'Order', 'Location']

export const INTEGRATION_LOGS_SEARCH_PARAMETERS = {
  parameter_like: '',
  created_at: '',
  created_by_like: '',
  sort_method: 'desc',
  order_by: 'created_at',
  limit: 15,
  page: 1,
}
